import React from 'react';

import Contact from '../components/Contact';
import Location from '../components/Location';

function Contato() {
    return (
        <div className="main-content">
            <Contact />
            <Location />
        </div>
    );
}

export default Contato;
