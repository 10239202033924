import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../layouts/TrabalheConosco.css';

const TrabalheConosco = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Usar EmailJS para enviar o formulário
        const templateParams = {
            name: formData.name,
            email: formData.email,
            message: formData.message,
        };

        emailjs.send(
            'service_1slbkl7', // Seu ID de serviço
            'template_sknks9b', // Seu ID de template
            templateParams,
            'VJH5svfCL52GXhbSP' // Seu ID de usuário EmailJS
        ).then((response) => {
            alert('Proposta enviada com sucesso!');
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        }).catch((err) => {
            alert('Ocorreu um erro ao enviar a proposta.');
        });
    };

    return (
        <div className='main-content'>
            <section className="trabalhe-conosco-container">
                <h2>Trabalhe Conosco</h2>
                <p>Envie seu currículo ou proposta de trabalho utilizando o formulário abaixo:</p>
                <form onSubmit={handleSubmit}>
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />

                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />

                    <label>Mensagem:</label>
                    <textarea
                        rows="5"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />

                    <button type="submit">Enviar Proposta</button>
                </form>
            </section>
        </div>
    );
};

export default TrabalheConosco;